
import { defineComponent } from "vue";
import _ from "lodash";
import { ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
// import { ElLoading } from "element-plus";
import Doughnut from "@/components/Data/Doughnut.vue";
const noop = () => {
  // no empty function
};
let intervalSession = setTimeout(noop, 10);
let intervalCountdown = setTimeout(noop, 10);

export default defineComponent({
  name: "OAICArchetypes",
  components: {
    Doughnut,
  },
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    //*
    OAIC_dominant_characteristicsMax(value) {
      // console.log("OAIC_dominant_characteristicsMax()");
      // console.log(value, "value");
      if (value == 0) {
        this.$emit("setCanNextStep", true);
      }
    },
    nextBTN_OAIC_dominantValidate(disabled) {
      // console.log("nextBTN_OAIC_dominantValidate()");
      // console.log(disabled, "disabled");
      if (!disabled) {
        this.$emit("setCanNextStep", true);
      }
    },
    //*/
    OAIC_dominant_characteristics: {
      handler(newValue) {
        if (newValue.personal_place) {
          this.personal_placeSlice(parseInt(newValue.personal_place));
        }
        if (newValue.dynamic_entrepreneurial_place) {
          this.dynamic_entrepreneurial_placeSlice(
            parseInt(newValue.dynamic_entrepreneurial_place)
          );
        }
        if (newValue.results_oriented) {
          this.results_orientedSlice(parseInt(newValue.results_oriented));
        }
        if (newValue.controlled_and_structured) {
          this.controlled_and_structuredSlice(
            parseInt(newValue.controlled_and_structured)
          );
        }
      },
      deep: true,
    },
    OAIC_organizational_leadership: {
      handler(newValue) {
        if (newValue.mentoring) {
          this.mentoringSlice(parseInt(newValue.mentoring));
        }
        if (newValue.entrepreneurship) {
          this.entrepreneurshipSlice(parseInt(newValue.entrepreneurship));
        }
        if (newValue.no_nonsense) {
          this.no_nonsenseSlice(parseInt(newValue.no_nonsense));
        }
        if (newValue.coordinating) {
          this.coordinatingSlice(parseInt(newValue.coordinating));
        }
      },
      deep: true,
    },
    OAIC_management_of_employees: {
      handler(newValue) {
        if (newValue.teamwork) {
          this.teamworkSlice(parseInt(newValue.teamwork));
        }
        if (newValue.individual_risk_taking) {
          this.individual_risk_takingSlice(
            parseInt(newValue.individual_risk_taking)
          );
        }
        if (newValue.hard_driving) {
          this.hard_drivingSlice(parseInt(newValue.hard_driving));
        }
        if (newValue.security) {
          this.securitySlice(parseInt(newValue.security));
        }
      },
      deep: true,
    },
    OAIC_organization_glue: {
      handler(newValue) {
        if (newValue.loyalty) {
          this.loyaltySlice(parseInt(newValue.loyalty));
        }
        if (newValue.commitment) {
          this.commitmentSlice(parseInt(newValue.commitment));
        }
        if (newValue.emphasis) {
          this.emphasisSlice(parseInt(newValue.emphasis));
        }
        if (newValue.formal_rules) {
          this.formal_rulesSlice(parseInt(newValue.formal_rules));
        }
      },
      deep: true,
    },
    OAIC_strategic_emphases: {
      handler(newValue) {
        if (newValue.human_development) {
          this.human_developmentSlice(parseInt(newValue.human_development));
        }
        if (newValue.resources_and_challenges) {
          this.resources_and_challengesSlice(
            parseInt(newValue.resources_and_challenges)
          );
        }
        if (newValue.achievement) {
          this.achievementSlice(parseInt(newValue.achievement));
        }
        if (newValue.stability) {
          this.stabilitySlice(parseInt(newValue.stability));
        }
      },
      deep: true,
    },
    OAIC_criteria_of_success: {
      handler(newValue) {
        if (newValue.human_resources) {
          this.human_resourcesSlice(parseInt(newValue.human_resources));
        }
        if (newValue.most_unique) {
          this.most_uniqueSlice(parseInt(newValue.most_unique));
        }
        if (newValue.winning_in_the_marketplace) {
          this.winning_in_the_marketplaceSlice(
            parseInt(newValue.winning_in_the_marketplace)
          );
        }
        if (newValue.efficiency) {
          this.efficiencySlice(parseInt(newValue.efficiency));
        }
      },
      deep: true,
    },
    doPreviousStep(value) {
      // console.log(`doPreviousStep(${value ? 'true' : 'false'})`);
      value && this.gotoPreviousStep();
    },
    doNextStep(value) {
      // console.log(`doNextStep(${value ? 'true' : 'false'})`);
      value && this.gotoNextStep();
    },
    timerLabel(value) {
      this.$emit("setTimerLabel", value);
    },
    timer(value) {
      const percentage =
        ((this.percentageLabel - value) / this.percentageLabel) * 100;
      this.$emit("setTimerPercentage", percentage);
    },
  },
  computed: {
    companyLogoUrl(): string {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    timeSection(): any {
      return this.$store.getters["routes/timeSection"];
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      return extratime;
    },
    oaicArchetypes(): any {
      const oaicArchetypes =
        this.$store.getters["assessmentSection/oaicArchetypes"] || {};
      return oaicArchetypes;
    },
    progressShowing() {
      const step = this.step;
      const array1 = [
        "OAIC_archetypes_1_6",
        "OAIC_archetypes_2_6",
        "OAIC_archetypes_3_6",
        "OAIC_archetypes_4_6",
        "OAIC_archetypes_5_6",
        "OAIC_archetypes_6_6",
      ];

      const found = array1.find((element) => element == step);
      if (found) {
        return true;
      }
      return false;
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    isLightColor(): boolean {
      const user = this.$store.getters["routes/user"];
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
    nextBTN_OAIC_dominantValidate() {
      if (
        this.OAIC_organizational_leadershipMax == 0 &&
        this.step == "OAIC_archetypes_2_6"
      ) {
        return false;
      } else if (
        this.OAIC_management_of_employeesMax == 0 &&
        this.step == "OAIC_archetypes_3_6"
      ) {
        return false;
      } else if (
        this.OAIC_organization_glueMax == 0 &&
        this.step == "OAIC_archetypes_4_6"
      ) {
        return false;
      } else if (
        this.OAIC_strategic_emphasesMax == 0 &&
        this.step == "OAIC_archetypes_5_6"
      ) {
        return false;
      } else if (
        this.OAIC_criteria_of_successMax == 0 &&
        this.step == "OAIC_archetypes_6_6"
      ) {
        return false;
      }
      return true;
    },
  },
  props: [
    "lastSection",
    "currentStep",
    "doPreviousStep",
    "doNextStep",
    "setTimerLabel",
    "setTimerPercentage",
  ],
  data() {
    return {
      step: "introduce_OAIC_archetypes", // introduce_OAIC_archetypes
      hasOpenEndedQuestion: false,
      skipOpenEndedQuestion: false,
      openedQuestion: "",
      saved: false,
      OAIC_dominant_characteristicsMax: 100,
      OAIC_organizational_leadershipMax: 100,
      OAIC_management_of_employeesMax: 100,
      OAIC_organization_glueMax: 100,
      OAIC_strategic_emphasesMax: 100,
      OAIC_criteria_of_successMax: 100,
      completeSectionsLabel: "",
      timer: 0,
      timerLabel: "0:00",
      percentageLabel: 0,
      OAIC_dominant_characteristics: {
        personal_place: 0,
        dynamic_entrepreneurial_place: 0,
        results_oriented: 0,
        controlled_and_structured: 0,
      },
      OAIC_organizational_leadership: {
        mentoring: 0,
        entrepreneurship: 0,
        no_nonsense: 0,
        coordinating: 0,
      },
      OAIC_management_of_employees: {
        teamwork: 0,
        individual_risk_taking: 0,
        hard_driving: 0,
        security: 0,
      },
      OAIC_organization_glue: {
        loyalty: 0,
        commitment: 0,
        emphasis: 0,
        formal_rules: 0,
      },
      OAIC_strategic_emphases: {
        human_development: 0,
        resources_and_challenges: 0,
        achievement: 0,
        stability: 0,
      },
      OAIC_criteria_of_success: {
        human_resources: 0,
        most_unique: 0,
        winning_in_the_marketplace: 0,
        efficiency: 0,
      },
      progressColor: "#5EB8F6",
      isStarted: false,
      stepsMap: {
        OAIC_archetypes_1_6: "dominantCharacteristics",
        OAIC_archetypes_2_6: "organizationalLeadership",
        OAIC_archetypes_3_6: "managementOfEmployees",
        OAIC_archetypes_4_6: "organizationGlue",
        OAIC_archetypes_5_6: "strategicEmphases",
        OAIC_archetypes_6_6: "criteriaOfSuccess",
      },
      timeout: 300,
    };
  },
  created() {
    window.addEventListener("keydown", this.bindKeyboardShortcuts);
  },
  unmounted() {
    window.removeEventListener("keydown", this.bindKeyboardShortcuts);
    this.$emit("setHasPreviousStep", false);
    this.$emit("setHasNextStep", false);
  },
  mounted() {
    // const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
    // this.hasOpenEndedQuestion = formTemplate.open_ended_question || false;
    this.timeout = 10 * 60 + this.extratime * 60;
    // this.timeout = 3600;
    const timer = this.timeout;
    const minutes = timer % 60;
    const minute = Math.floor(timer / 60);
    this.timerLabel = `${minute}:${minutes < 10 ? "0" + minutes : minutes}`;

    // @deprecated
    // this.countDownTimeSession();

    this.$emit("setHasPreviousStep", true);
    this.$emit("setCanPreviousStep", false);

    this.$emit("setHasNextStep", true);
    this.$emit("setCanNextStep", false);

    // Get started with popup
    if (!this.isStarted) {
      const order = this.currentStep.order - 1;
      ElMessageBox.alert(
        (this as any).$t("question.cultureProfile.get_started_message", {
          step: order,
          minute: minute,
        }),
        (this as any).$t("question.common.move_on_step_n", {
          step: order,
        }),
        {
          dangerouslyUseHTMLString: true,
          showClose: true,
          confirmButtonText: (this as any).$t("question.common.Start"),
          callback: () => {
            this.getStarted();
          },
        }
      );
    }
  },
  methods: {
    applyStepButtons() {
      // console.log("applyStepButtons()", this.step);
      if (
        this.step == "OAIC_archetypes_1_6" ||
        this.step == "OAIC_archetypes_2_6" ||
        this.step == "OAIC_archetypes_3_6" ||
        this.step == "OAIC_archetypes_4_6" ||
        this.step == "OAIC_archetypes_5_6" ||
        this.step == "OAIC_archetypes_6_6"
      ) {
        const key = this.stepsMap[this.step];
        let groupMaxValue = 100;
        _.each(this.oaicArchetypes.scenarios[key].questions, (question) => {
          groupMaxValue -= parseInt(question.value);
        });
        const order = String(
          this.step.split("OAIC_archetypes_")[1].split("_")[0]
        );
        switch (order) {
          case "1":
            this.OAIC_dominant_characteristicsMax = groupMaxValue;
            break;
          case "2":
            this.OAIC_organizational_leadershipMax = groupMaxValue;
            break;
          case "3":
            this.OAIC_management_of_employeesMax = groupMaxValue;
            break;
          case "4":
            this.OAIC_organization_glueMax = groupMaxValue;
            break;
          case "5":
            this.OAIC_strategic_emphasesMax = groupMaxValue;
            break;
          case "6":
            this.OAIC_criteria_of_successMax = groupMaxValue;
            break;
        }
        // console.log("groupMaxValue", groupMaxValue);
        this.$emit("setCanNextStep", groupMaxValue == 0);
        this.$emit("setCanPreviousStep", this.step != "OAIC_archetypes_1_6");
        this.$emit("setHasNextStep", this.step != "OAIC_archetypes_6_6");
      }
    },
    getStarted() {
      // console.log("getStarted()");
      this.step = "OAIC_archetypes_1_6";
      // @deprecated
      // this.clearIntervalSession();
      this.countDownTime();
      this.applyStepButtons();
      this.isStarted = true;
    },
    gotoPreviousStep() {
      // console.log("gotoPreviousStep()");
      // console.log(this.step, "this.step");
      if (
        this.step == "OAIC_archetypes_2_6" ||
        this.step == "OAIC_archetypes_3_6" ||
        this.step == "OAIC_archetypes_4_6" ||
        this.step == "OAIC_archetypes_5_6" ||
        this.step == "OAIC_archetypes_6_6"
      ) {
        const order =
          parseInt(this.step.split("OAIC_archetypes_")[1].split("_")[0]) - 1;
        this.step = `OAIC_archetypes_${order}_6`;
        // console.log(this.step, "back to step");
        this.applyStepButtons();
      }
    },
    gotoNextStep() {
      // console.log("gotoNextStep()");
      // console.log(this.step, "this.step");
      if (
        this.step == "OAIC_archetypes_1_6" ||
        this.step == "OAIC_archetypes_2_6" ||
        this.step == "OAIC_archetypes_3_6" ||
        this.step == "OAIC_archetypes_4_6" ||
        this.step == "OAIC_archetypes_5_6" ||
        this.step == "OAIC_archetypes_6_6"
      ) {
        this.applyStepButtons();
        this.nextBTN_OAIC_dominant();
        this.applyStepButtons();
        this.$emit("setCanPreviousStep", this.step != "OAIC_archetypes_1_6");
        if (this.step == "OAIC_archetypes_6_6") {
          // @fixed
          // console.log("yes...: suspend");
          // this.lastSection ? this.finishAssessment() : this.nextSectionBTN();
        }
      } else if (this.step == "opened_question") {
        this.finishOpenedQuestion();
        this.$emit("setCanNextStep", true);
      } else if (this.step == "complete") {
        this.lastSection ? this.finishAssessment() : this.nextSectionBTN();
      }
      // this.setDoNextStep(false);
    },
    bindKeyboardShortcuts(event: KeyboardEvent) {
      if (event.ctrlKey && event.key == "1") {
        if (
          this.step == "OAIC_archetypes_1_6" ||
          this.step == "OAIC_archetypes_2_6" ||
          this.step == "OAIC_archetypes_3_6" ||
          this.step == "OAIC_archetypes_4_6" ||
          this.step == "OAIC_archetypes_5_6" ||
          this.step == "OAIC_archetypes_6_6"
        ) {
          const key = this.stepsMap[this.step];
          const scores = [
            [25, 25, 25, 25],
            [10, 20, 30, 40],
            [40, 30, 20, 10],
            [30, 20, 20, 30],
            [15, 15, 30, 40],
            [20, 15, 10, 55],
            [30, 15, 30, 25],
            [40, 10, 20, 30],
            [30, 40, 10, 20],
            [50, 10, 5, 35],
            [60, 5, 5, 30],
            [20, 30, 20, 30],
            [10, 10, 30, 50],
          ];
          const score = scores[Math.floor(Math.random() * scores.length)];
          _.each(
            this.oaicArchetypes.scenarios[key].questions,
            (question, i) => {
              // console.log(question, "question", i, "i");
              const $event = {
                target: {
                  value: score[parseInt(i)],
                },
              };
              this.onChangeWeight($event, key, question);
            }
          );
        }
      }
    },
    getQuestionValue(section: string, idx: number): number {
      return _.get(
        this.oaicArchetypes,
        "scenarios." + section + ".questions[" + idx + "].value"
      );
    },
    onChangeWeight($event: any, path: string, question: any) {
      let value = parseInt($event.target.value);
      if (isNaN(value)) {
        value = 0;
      }
      // if (value > 99) {
      //   value = 100;
      // }
      $event.target.value = value;
      question.value = value;
      question.dirty = true;

      let totalValue = 0;
      for (const i in this.oaicArchetypes.scenarios[path].questions) {
        if (question.id == this.oaicArchetypes.scenarios[path].questions[i].id)
          continue;
        const q = this.oaicArchetypes.scenarios[path].questions[i];
        totalValue += q.value;
      }

      // auto fill sibling inputs (average percent input)
      const totalQuestions =
        this.oaicArchetypes.scenarios[path].questions.length;
      let totalDirty = 0;
      totalValue += question.value;
      for (const i in this.oaicArchetypes.scenarios[path].questions) {
        if (question.id == this.oaicArchetypes.scenarios[path].questions[i].id)
          continue;
        const isDirty = this.oaicArchetypes.scenarios[path].questions[i].dirty;
        if (!isDirty) {
          totalDirty++;
          totalValue += parseInt(
            this.oaicArchetypes.scenarios[path].questions[i].value
          );
        }
      }

      // dirty all sibling inputs if full score
      if (totalValue == 100) {
        if (totalDirty < totalQuestions) {
          for (const i in this.oaicArchetypes.scenarios[path].questions) {
            if (!this.oaicArchetypes.scenarios[path].questions[i].dirty) {
              this.oaicArchetypes.scenarios[path].questions[i].dirty = true;
            }
          }
        }
      }

      /*
      // auto trig to next button with original frontend logic
      // @see nextBTN_OAIC_dominantValidate()
      // 1: dominantCharacteristics
      // 2: organizationalLeadership
      // 3: managementOfEmployees
      // 4: organizationGlue
      // 5: strategicEmphases
      // 6: criteriaOfSuccess
      const groupMaxValue = 100 - totalValue;
      const order = parseInt(
        this.step.split("OAIC_archetypes_")[1].split("_")[0]
      );

      // console.log(groupMaxValue, "groupMaxValue");
      switch (String(order)) {
        case "1":
          this.OAIC_dominant_characteristicsMax = groupMaxValue;
          break;
        case "2":
          this.OAIC_organizational_leadershipMax = groupMaxValue;
          break;
        case "3":
          this.OAIC_management_of_employeesMax = groupMaxValue;
          break;
        case "4":
          this.OAIC_organization_glueMax = groupMaxValue;
          break;
        case "5":
          this.OAIC_strategic_emphasesMax = groupMaxValue;
          break;
        case "6":
          this.OAIC_criteria_of_successMax = groupMaxValue;
          break;
      }
      //*/

      this.applyStepButtons();
    },
    validateGroupValue(path: string) {
      const totalQuestions =
        this.oaicArchetypes.scenarios[path].questions.length;
      let totalDirty = 0;
      let totalValue = 0;
      let valid = true;
      for (const i in this.oaicArchetypes.scenarios[path].questions) {
        if (this.oaicArchetypes.scenarios[path].questions[i].dirty) {
          totalDirty++;
          totalValue += parseInt(
            this.oaicArchetypes.scenarios[path].questions[i].value
          );
        }
      }
      if (totalDirty == totalQuestions) {
        valid = totalValue == 100;
      }
      return valid;
    },
    // OAIC_criteria_of_success
    human_resourcesSlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.human_resources = 100;
      } else {
        this.OAIC_criteria_of_success.human_resources = value;
      }
    },
    most_uniqueSlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.most_unique = 100;
      } else {
        this.OAIC_criteria_of_success.most_unique = value;
      }
    },
    winning_in_the_marketplaceSlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.winning_in_the_marketplace = 100;
      } else {
        this.OAIC_criteria_of_success.winning_in_the_marketplace = value;
      }
    },
    efficiencySlice(value: number) {
      if (value > 99) {
        this.OAIC_criteria_of_success.efficiency = 100;
      } else {
        this.OAIC_criteria_of_success.efficiency = value;
      }
    },
    // OAIC_strategic_emphases
    human_developmentSlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.human_development = 100;
      } else {
        this.OAIC_strategic_emphases.human_development = value;
      }
    },
    resources_and_challengesSlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.resources_and_challenges = 100;
      } else {
        this.OAIC_strategic_emphases.resources_and_challenges = value;
      }
    },
    achievementSlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.achievement = 100;
      } else {
        this.OAIC_strategic_emphases.achievement = value;
      }
    },
    stabilitySlice(value: number) {
      if (value > 99) {
        this.OAIC_strategic_emphases.stability = 100;
      } else {
        this.OAIC_strategic_emphases.stability = value;
      }
    },
    // OAIC_organization_glue
    loyaltySlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.loyalty = 100;
      } else {
        this.OAIC_organization_glue.loyalty = value;
      }
    },
    commitmentSlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.commitment = 100;
      } else {
        this.OAIC_organization_glue.commitment = value;
      }
    },
    emphasisSlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.emphasis = 100;
      } else {
        this.OAIC_organization_glue.emphasis = value;
      }
    },
    formal_rulesSlice(value: number) {
      if (value > 99) {
        this.OAIC_organization_glue.formal_rules = 100;
      } else {
        this.OAIC_organization_glue.formal_rules = value;
      }
    },
    // OAIC_management_of_employees
    teamworkSlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.teamwork = 100;
      } else {
        this.OAIC_management_of_employees.teamwork = value;
      }
    },
    individual_risk_takingSlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.individual_risk_taking = 100;
      } else {
        this.OAIC_management_of_employees.individual_risk_taking = value;
      }
    },
    hard_drivingSlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.hard_driving = 100;
      } else {
        this.OAIC_management_of_employees.hard_driving = value;
      }
    },
    securitySlice(value: number) {
      if (value > 99) {
        this.OAIC_management_of_employees.security = 100;
      } else {
        this.OAIC_management_of_employees.security = value;
      }
    },
    // OAIC_organizational_leadership
    mentoringSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.mentoring = 100;
      } else {
        this.OAIC_organizational_leadership.mentoring = value;
      }
    },
    entrepreneurshipSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.entrepreneurship = 100;
      } else {
        this.OAIC_organizational_leadership.entrepreneurship = value;
      }
    },
    no_nonsenseSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.no_nonsense = 100;
      } else {
        this.OAIC_organizational_leadership.no_nonsense = value;
      }
    },
    coordinatingSlice(value: number) {
      if (value > 99) {
        this.OAIC_organizational_leadership.coordinating = 100;
      } else {
        this.OAIC_organizational_leadership.coordinating = value;
      }
    },
    // OAIC_dominant_characteristics
    personal_placeSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.personal_place = 100;
      } else {
        this.OAIC_dominant_characteristics.personal_place = value;
      }
    },
    dynamic_entrepreneurial_placeSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.dynamic_entrepreneurial_place = 100;
      } else {
        this.OAIC_dominant_characteristics.dynamic_entrepreneurial_place =
          value;
      }
    },
    results_orientedSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.results_oriented = 100;
      } else {
        this.OAIC_dominant_characteristics.results_oriented = value;
      }
    },
    controlled_and_structuredSlice(value: number) {
      if (value > 99) {
        this.OAIC_dominant_characteristics.controlled_and_structured = 100;
      } else {
        this.OAIC_dominant_characteristics.controlled_and_structured = value;
      }
    },
    nextBTN_OAIC_dominant() {
      // console.log("nextBTN_OAIC_dominant()", this.step);
      if (
        this.OAIC_dominant_characteristicsMax == 0 &&
        this.step == "OAIC_archetypes_1_6"
      ) {
        this.step = "OAIC_archetypes_2_6";
      } else if (
        this.OAIC_organizational_leadershipMax == 0 &&
        this.step == "OAIC_archetypes_2_6"
      ) {
        this.step = "OAIC_archetypes_3_6";
      } else if (
        this.OAIC_management_of_employeesMax == 0 &&
        this.step == "OAIC_archetypes_3_6"
      ) {
        this.step = "OAIC_archetypes_4_6";
      } else if (
        this.OAIC_organization_glueMax == 0 &&
        this.step == "OAIC_archetypes_4_6"
      ) {
        this.step = "OAIC_archetypes_5_6";
      } else if (
        this.OAIC_strategic_emphasesMax == 0 &&
        this.step == "OAIC_archetypes_5_6"
      ) {
        this.step = "OAIC_archetypes_6_6";
      } else if (
        this.OAIC_criteria_of_successMax == 0 &&
        this.step == "OAIC_archetypes_6_6"
      ) {
        // console.log("yes ???");
        // this.finishOAIC();
        this.saveTimeSection();
        this.lastSection ? this.finishAssessment() : this.nextSectionBTN();
      }
    },
    finishOpenedQuestion() {
      // console.log("finishOpenedQuestion", this.openedQuestion);
      this.step = "complete";
    },
    makeFormValues() {
      let values: any = {};
      const name = this.oaicArchetypes.name;
      values[name] = {};
      for (const i in this.oaicArchetypes.scenarios) {
        const group = _.snakeCase(this.oaicArchetypes.scenarios[i].name);
        values[name][group] = {};
        for (const j in this.oaicArchetypes.scenarios[i].questions) {
          const question = this.oaicArchetypes.scenarios[i].questions[j];
          const answer = { id: question.id, value: parseInt(question.value) };
          values[name][group][answer.id] = answer.value;
        }
      }
      if (this.hasOpenEndedQuestion && !this.skipOpenEndedQuestion) {
        const feedback = this.openedQuestion || "";
        values.oaic_archetypes_feedback = feedback.trim();
      }
      return values;
    },
    finishOAIC() {
      this.step = this.hasOpenEndedQuestion ? "opened_question" : "complete";
    },
    nextSectionBTN() {
      clearInterval(intervalCountdown);
      const values = this.makeFormValues();
      this.$emit("finishValueAndTraits", values);
    },
    async finishAssessment() {
      // console.log("finishAssessment()");
      clearInterval(intervalCountdown);
      const values = this.saved ? null : this.makeFormValues();
      await this.$store.dispatch("assessment/finish", values, { root: true });
      this.saved = true;
      this.$emit("finishAssessment");
      /*
      if (window.closed) {
        window.close();
      } else {
        // window.open("https://myculture.happily.ai/", "_self");
        const formTemplate =
          this.$store.getters["assessment/formTemplate"] || {};
        const redirectUrl =
          formTemplate.redirect_url ||
          "https://myculture.ai/thank-you-for-submitting";
        window.open(redirectUrl, "_self");
      }
      //*/
    },
    partAnwser(): boolean {
      const value =
        this.step == "OAIC_archetypes_1_6" ||
        this.step == "OAIC_archetypes_2_6" ||
        this.step == "OAIC_archetypes_3_6" ||
        this.step == "OAIC_archetypes_4_6" ||
        this.step == "OAIC_archetypes_5_6" ||
        this.step == "OAIC_archetypes_6_6";
      return value;
    },
    backBTN_OAIC_dominant() {
      if (this.step == "OAIC_archetypes_2_6") {
        this.step = "OAIC_archetypes_1_6";
      } else if (this.step == "OAIC_archetypes_3_6") {
        this.step = "OAIC_archetypes_2_6";
      } else if (this.step == "OAIC_archetypes_4_6") {
        this.step = "OAIC_archetypes_3_6";
      } else if (this.step == "OAIC_archetypes_5_6") {
        this.step = "OAIC_archetypes_4_6";
      } else if (this.step == "OAIC_archetypes_6_6") {
        this.step = "OAIC_archetypes_5_6";
      }
    },
    countDownTime() {
      // const timer = 5 * 60 + this.extratime * 60;
      const timer = this.timeout;
      this.percentageLabel = timer;
      this.timer = timer;
      intervalCountdown = setInterval(() => {
        if (this.timer === 0) {
          // console.log("countDownTime()");
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_1.title"),
            (this as any).$t("popup.alert.alert_box_1.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t("popup.optional.Continue"),
              callback: () => {
                this.saveTimeSection();
                if (this.hasOpenEndedQuestion) {
                  this.step = "opened_question";
                  this.nextSectionTimeOut();
                } else {
                  this.nextSectionTimeOut();
                  if (this.lastSection) {
                    this.finishAssessment();
                  }
                  this.nextSectionBTN();
                }
              },
            }
          );
          clearInterval(intervalCountdown);
        } else if (this.step == "complete" || this.step == "opened_question") {
          clearInterval(intervalCountdown);
        } else {
          this.timer--;
          let minutes = this.timer % 60;
          this.timerLabel = `${Math.floor(this.timer / 60)}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
      }, 1000);
    },
    clearIntervalSession() {
      clearInterval(intervalSession);
    },
    countDownTimeSession() {
      // this.timer = 5 * 60;
      this.timer = this.timeout;
      intervalSession = setInterval(() => {
        if (this.timer === 0) {
          // console.log("countDownTimeSession()");
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_2.title"),
            (this as any).$t("popup.alert.alert_box_2.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t(
                "popup.optional.Go_to_Next_Section"
              ),
              callback: () => {
                this.nextSectionBTN();
              },
            }
          );
          clearInterval(intervalSession);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    async nextSectionTimeOut() {
      const code = this.$router.currentRoute.value.params.code || "";
      const statusSectionOAICA = true;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/sectionStates",
        { code, statusSectionOAICA },
        {
          root: true,
        }
      );
    },
    async saveTimeSection() {
      const code = this.$router.currentRoute.value.params.code || "";
      const timeSectionOAICA = this.timer ? this.timer : 0;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/saveSectionTime",
        { code, timeSectionOAICA },
        { root: true }
      );
    },
    nl2br(value: string) {
      return value.replace(/([^>])\n/g, "$1<br />\n");
    },
  },
});
