
import { defineComponent } from "vue";
import _ from "lodash";
import { ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
// import { ElLoading } from "element-plus";
const noop = () => {
  // no empty function
};
let intervalSession = setTimeout(noop, 10);
let intervalCountdown = setTimeout(noop, 10);

export default defineComponent({
  name: "AcceptableBehaviors",
  inheritAttrs: false,
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    doPreviousStep(value) {
      // console.log(`doPreviousStep(${value ? 'true' : 'false'})`);
      value && this.gotoPreviousStep();
    },
    doNextStep(value) {
      // console.log(`doNextStep(${value ? 'true' : 'false'})`);
      value && this.gotoNextStep();
    },
    timerLabel(value) {
      this.$emit("setTimerLabel", value);
    },
    timer(value) {
      const percentage =
        ((this.percentageLabel - value) / this.percentageLabel) * 100;
      this.$emit("setTimerPercentage", percentage);
    },
  },
  computed: {
    companyLogoUrl(): any {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      return extratime;
    },
    acceptableBehaviors(): any {
      const acceptableBehaviors =
        this.$store.getters["assessmentSection/acceptableBehaviors"];
      const formTemplate = this.$store.getters["assessment/formTemplate"];

      const isCustomFields = formTemplate
        ? formTemplate.acceptable_behaviors_is_custom
        : false;

      if (acceptableBehaviors && isCustomFields) {
        const customFields = formTemplate.acceptable_behaviors_custom_fields;

        // TODO: i18n
        const defaultQuestion = {
          checked: false,
          dirty: false,
          id: "",
          name: "",
          options: [
            { label: "Acceptable", value: 0, label_th: "ยอมรับได้" },
            { label: " Frowned Upon", label_th: "ไม่พอใจ/ไม่ยอมรับ", value: 1 },
            {
              label_th: "แสดงออกว่าไม่สนับสนุน",
              label: "Actively Discouraged",
              value: 2,
            },
            {
              label: "Reprimanded",
              value: 3,
              label_th: "ต้องมีการตำหนิหรือลงโทษ",
            },
            {
              value: 4,
              label_th: "ยอมรับไม่ได้อย่างเด็ดขาด",
              label: " Zero Tolerance Policy",
            },
          ],
          order: 1,
          title: "",
          title_th: "",
          type: "choice",
          value: "",
        };
        const newScenarios = [];
        let order = 1;
        for (const i in customFields) {
          const uiQuestion = customFields[i];
          let targetQuestion: any = { id: 1, order: 1 };
          // console.log(uiQuestion, "uiQuestion");
          _.merge(targetQuestion, defaultQuestion, {
            id: uiQuestion.id,
            name: uiQuestion.label,
            // order: uiQuestion.index,
            order: order++,
            title: uiQuestion.label,
            title_th: uiQuestion.labelTH,
          });
          const scenario = {
            description:
              '<div class="">Please provide a rating for each scenario based on your preference and tolerance levels.</div>',
            description_th:
              '<div class="">' +
              helpers.t(
                "question.acceptableBehaviors.Please_provide_a_rating_for_each"
              ) +
              "</div>",
            id: targetQuestion.id,
            name: "scenario_" + targetQuestion.order,
            order: targetQuestion.order,
            questions: [targetQuestion],
            title: targetQuestion.title, //"Evaluating Acceptable Behaviors",
            title_th: targetQuestion.title_th /*helpers.t(
              "question.acceptableBehaviors.Evaluating_Acceptable_Behaviors"
            ),*/,
          };
          newScenarios.push(scenario);
        }
        acceptableBehaviors.scenarios = newScenarios;
      }

      return acceptableBehaviors;
    },
    progressShowing() {
      const step = this.step;
      const array1 = ["Acceptable_Behaviors_Setup"];

      const found = array1.find((element) => element == step);
      if (found) {
        return true;
      }
      return false;
    },
    selectScenario() {
      let value = true;
      const scenario = this.acceptableBehaviorsScenario;
      if (scenario[this.scenarioIndexShowing - 1].value) {
        value = false;
      }
      return value;
    },
    timeSection(): any {
      return this.$store.getters["routes/timeSection"];
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    isLightColor(): boolean {
      const user = this.$store.getters["routes/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
    formTemplate(): any {
      return this.$store.getters["assessment/formTemplate"] || {};
    },
    isV2(): boolean {
      return Object.prototype.hasOwnProperty.call(
        this.formTemplate,
        "values_and_traits_v_2"
      );
    },
  },
  props: [
    "lastSection",
    "currentStep",
    "doPreviousStep",
    "doNextStep",
    "setTimerLabel",
    "setTimerPercentage",
  ],
  data() {
    return {
      step: "introduce_acceptable_behaviors", // introduce_acceptable_behaviors
      hasOpenEndedQuestion: false,
      skipOpenEndedQuestion: false,
      openedQuestion: "",
      canNextStep: false,
      canNextStepAll: false,
      saved: false,
      // get_start
      // Evaluating_Acceptable_Behaviors_Tutorial
      // Acceptable_Behaviors_Setup
      // complete
      scenarioIndexShowing: 1,
      acceptableBehaviorsScenario: [
        {
          id: "1_late_to_work",
          label: "Regularly showing up 5-min late to work",
          value: "",
          index: 1,
        },
        {
          id: "2_Unresponsive_over_chat",
          label:
            "Unresponsive over chat for most of the day without an away status.",
          value: "",
          index: 2,
        },
        {
          id: "3_headphones_and_listening_to_music",
          label: "Using headphones and listening to music while working",
          value: "",
          index: 3,
        },
        {
          id: "4444",
          label: "Aacceptable Behaviors Scenario - 4",
          value: "",
          index: 4,
        },
        {
          id: "5555",
          label: "Aacceptable Behaviors Scenario - 5",
          value: "",
          index: 5,
        },
        {
          id: "66666",
          label: "Aacceptable Behaviors Scenario - 6",
          value: "",
          index: 6,
        },
      ],
      timer: 0,
      timerLabel: "0:00",
      percentageLabel: 0,
      isStarted: false,
      timeout: 300,
    };
  },
  mounted() {
    // const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
    // this.hasOpenEndedQuestion = formTemplate.open_ended_question || false;

    this.timeout = 5 * 60 + this.extratime * 60;
    // this.timeout = 3600;

    const minutes = this.timeout % 60;
    const minute = Math.floor(this.timeout / 60);
    this.timerLabel = `${minute}:${minutes < 10 ? "0" + minutes : minutes}`;

    // @deprecated
    // this.countDownTimeSession();

    // Get started with popup
    if (!this.isStarted) {
      const order = this.currentStep.order - 1;
      ElMessageBox.alert(
        (this as any).$t("question.acceptableBehaviors.get_started_message", {
          step: order,
          minute: minute,
        }),
        (this as any).$t("question.common.move_on_step_n", {
          step: order,
        }),
        {
          dangerouslyUseHTMLString: true,
          showClose: true,
          confirmButtonText: (this as any).$t("question.common.Start"),
          callback: () => {
            this.getStarted();
          },
        }
      );
    }
  },
  created() {
    // window.addEventListener("keydown", this.bindKeyboardShortcuts);
  },
  unmounted() {
    // window.removeEventListener("keydown", this.bindKeyboardShortcuts);
  },
  methods: {
    getStarted() {
      this.step = "Acceptable_Behaviors";
      // @deprecated
      // this.clearIntervalSession();
      this.countDownTime();
    },
    gotoPreviousStep() {
      console.log("gotoPreviousStep()");
    },
    gotoNextStep() {
      // console.log("gotoNextStep()");
      // this.finishAcceptableBehaviors();
      this.saveTimeSection();
      this.lastSection ? this.finishAssessment() : this.nextSectionBTN();
    },
    bindKeyboardShortcuts(event: KeyboardEvent) {
      if (this.step != "Acceptable_Behaviors") return;
      const choices = ["0", "1", "2", "3", "4"];
      if (_.indexOf(choices, event.key) === -1) return;
      const scenarios = this.acceptableBehaviors.scenarios;
      const key = parseInt(event.key);
      for (const i in scenarios) {
        const scenario = scenarios[i];
        if (scenario.order == this.scenarioIndexShowing) {
          const question = scenario.questions[0];
          const options = question.options;
          for (const j in options) {
            const option = options[j];
            const value = parseInt(option.value);
            if (value == key) {
              this.onClickOption(scenario, question, option);
              break;
            }
          }
        }
      }
    },
    optionCls(option: { value: any }) {
      const colors = [
        "color-15CE95",
        "color-FAA13A",
        "color-FA8B3A",
        "color-FA5F3A",
        "color-FA3A3A",
      ];
      const index = parseInt(option.value);
      return colors[index] ? colors[index] : "";
    },
    onClickOption(scenario: any, question: any, option: any) {
      const scenarios = this.acceptableBehaviors.scenarios;
      let count = 0;
      for (const i in scenarios) {
        if (scenarios[i].id == scenario.id) {
          for (const j in scenarios[i].questions) {
            for (const k in scenarios[i].questions[j].options) {
              const o = scenarios[i].questions[j].options[k];
              o.checked = o.value == option.value;
              if (o.checked) {
                this.canNextStep = true;
                count++;
              }
            }
          }
        } else {
          for (const j in scenarios[i].questions) {
            for (const k in scenarios[i].questions[j].options) {
              const o = scenarios[i].questions[j].options[k];
              if (o.checked) {
                count++;
                break;
              }
            }
          }
        }
      }
      // console.log(count, "count", scenarios.length, "scenarios.length");
      // this.canNextStepAll = count == scenarios.length;
      this.canNextStepAll = count == Object.keys(scenarios).length;
      this.$emit("setCanNextStep", this.canNextStepAll);
    },
    applyStepperButtons() {
      // can next step if already select choice
      let isChecked = false;
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].order != this.scenarioIndexShowing) continue;
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              isChecked = true;
            }
          }
        }
      }
      this.canNextStep = isChecked;
    },
    finishAcceptableBehaviors() {
      // this.step = "opened_question";
      this.step = this.hasOpenEndedQuestion ? "opened_question" : "complete";
    },
    makeFormValues() {
      let values: any = {};
      const name = this.acceptableBehaviors.name;
      values[name] = {};
      const scenarios = this.acceptableBehaviors.scenarios;
      for (const i in scenarios) {
        const group = _.snakeCase(this.acceptableBehaviors.scenarios[i].name);
        values[name][group] = {};
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              const answer = {
                id: question.id,
                value: parseInt(question.options[k].value),
              };
              values[name][group][answer.id] = answer.value;
            }
          }
        }
      }
      if (this.hasOpenEndedQuestion && !this.skipOpenEndedQuestion) {
        const feedback = this.openedQuestion || "";
        values.acceptable_behaviors_feedback = feedback.trim();
      }
      return values;
    },
    async finishAssessment() {
      // this.$router.push({ name: "Assessments" });
      clearInterval(intervalCountdown);
      const values = this.saved ? null : this.makeFormValues();
      await this.$store.dispatch("assessment/finish", values, { root: true });
      this.saved = true;
      this.$emit("finishAssessment");
      // if (window.closed) {
      //   window.close();
      // } else {
      //   // window.open("https://myculture.happily.ai/", "_self");
      //   const formTemplate =
      //     this.$store.getters["assessment/formTemplate"] || {};
      //   const redirectUrl =
      //     formTemplate.redirect_url ||
      //     "https://myculture.ai/thank-you-for-submitting";
      //   window.open(redirectUrl, "_self");
      // }
    },
    countDownTime() {
      this.percentageLabel = this.timeout;
      this.timer = this.timeout;
      intervalCountdown = setInterval(() => {
        if (this.timer === 0) {
          // console.log("countDownTime()");
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_1.title"),
            (this as any).$t("popup.alert.alert_box_1.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t("popup.optional.Continue"),
              callback: () => {
                this.saveTimeSection();
                if (this.hasOpenEndedQuestion) {
                  this.step = "opened_question";
                  this.nextSectionTimeOut();
                } else {
                  this.nextSectionTimeOut();
                  if (this.lastSection) {
                    this.finishAssessment();
                  }
                  this.nextSectionBTN();
                }
              },
            }
          );
          clearInterval(intervalCountdown);
        } else if (this.step == "complete" || this.step == "opened_question") {
          clearInterval(intervalCountdown);
        } else {
          this.timer--;
          let minutes = this.timer % 60;
          this.timerLabel = `${Math.floor(this.timer / 60)}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
      }, 1000);
    },
    clearIntervalSession() {
      clearInterval(intervalSession);
    },
    countDownTimeSession() {
      // this.timer = 5 * 60;
      this.timer = this.timeout;
      intervalSession = setInterval(() => {
        if (this.timer === 0) {
          console.log("countDownTimeSession()");
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_2.title"),
            (this as any).$t("popup.alert.alert_box_2.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t(
                "popup.optional.Go_to_Next_Section"
              ),
              callback: () => {
                this.nextSectionBTN();
              },
            }
          );
          clearInterval(intervalSession);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    setupPreviousStep() {
      const index = this.scenarioIndexShowing;
      if (index == 1) {
        this.step = "Evaluating_Acceptable_Behaviors_Tutorial";
      } else {
        this.scenarioIndexShowing = index - 1;
      }
    },
    setupNexStep() {
      if (!this.canNextStep) return;

      this.scenarioIndexShowing++;
      this.applyStepperButtons();
      if (
        this.scenarioIndexShowing >
        Object.keys(this.acceptableBehaviors.scenarios).length
      ) {
        this.finishAcceptableBehaviors();
        this.saveTimeSection();
      }
    },
    finishOpenedQuestion() {
      this.step = "complete";
    },
    nextSectionBTN() {
      clearInterval(intervalCountdown);
      const values = this.makeFormValues();
      this.$emit("finishValueAndTraits", values);
    },
    // @todo I'll refactor this after launch beta version (common function)
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    async nextSectionTimeOut() {
      const code = this.$router.currentRoute.value.params.code || "";
      const statusSectionAccep = true;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/sectionStates",
        { code, statusSectionAccep },
        {
          root: true,
        }
      );
    },
    async saveTimeSection() {
      const code = this.$router.currentRoute.value.params.code || "";
      const timeSectionAccep = this.timer ? this.timer : 0;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/saveSectionTime",
        { code, timeSectionAccep },
        { root: true }
      );
    },
    nl2br(value: string) {
      return value.replace(/([^>])\n/g, "$1<br />\n");
    },
  },
});
