const settings = {
  VALUES_ALIGNMENT_TIME: 5,
  CULTURE_PROFILE_TIME: 10,
  HUMAN_SKILLS_TIME: 10,
  ACCEPTABLE_BEHAVIORS_TIME: 5,
  WORK_STYLE_IDENTIFIER_TIME: 5,
  LOGIC_TEST_TIME: 10,
  CUSTOM_SURVEY_TIME: 10,
};

export default settings;
