
import { defineComponent } from "vue";
import _, { delay } from "lodash";
import { ElMessageBox } from "element-plus";
import helpers from "@/helpers/global";
// import { ElLoading } from "element-plus";
const noop = () => {
  // no empty function
};
let intervalSession = setTimeout(noop, 10);
let intervalCountdown = setTimeout(noop, 10);

export default defineComponent({
  name: "CustomSuevey",
  inheritAttrs: false,
  watch: {
    step(value) {
      if (value) {
        window.scrollTo(0, 0);
      }
    },
    doPreviousStep(value) {
      // console.log(`doPreviousStep(${value ? 'true' : 'false'})`);
      value && this.gotoPreviousStep();
    },
    doNextStep(value) {
      // console.log(`doNextStep(${value ? 'true' : 'false'})`);
      value && this.gotoNextStep();
    },
    timerLabel(value) {
      this.$emit("setTimerLabel", value);
    },
    timer(value) {
      const percentage =
        ((this.percentageLabel - value) / this.percentageLabel) * 100;
      this.$emit("setTimerPercentage", percentage);
    },
  },
  computed: {
    companyLogoUrl(): any {
      const defaultLogoUrl = "";
      const companyLogoUrl = this.$store.getters["user/companyLogoUrl"];
      return companyLogoUrl || defaultLogoUrl;
    },
    extratime(): any {
      const formTemplate = this.$store.getters["assessment/formTemplate"] || {};
      const extratime = Number(formTemplate.extra_time) || 0;
      return extratime;
    },
    customSurvey(): any {
      const customSurvey = this.$store.getters["assessment/formTemplate"];
      const isCustomFields = customSurvey
        ? customSurvey.custom_survey_is_custom
        : false;
      if (isCustomFields) {
        const customFields = customSurvey.custom_survey_custom_fields;

        const newScenarios = [];
        let order = 1;
        for (const i in customFields) {
          const uiQuestion = customFields[i];
          let targetQuestion: any = { id: 1, order: 1 };
          if (uiQuestion.type === "open-ended") {
            targetQuestion = {
              name: uiQuestion.question,
              description: uiQuestion.description,
              order: order++,
              type: "open-ended",
            };
          } else if (uiQuestion.type === "multiple-choice") {
            targetQuestion = {
              name: uiQuestion.question,
              description: uiQuestion.description,
              order: order++,
              type: "multiple-choice",
              choices: uiQuestion.choices.map(
                (choice: { name: any; value: any; isCorrect: any }) => ({
                  name: choice.value,
                  value: choice.value,
                  isCorrect: choice.isCorrect,
                })
              ),
            };
          } else if (uiQuestion.type === "multiple-select") {
            targetQuestion = {
              name: uiQuestion.question,
              description: uiQuestion.description,
              order: order++,
              type: "multiple-select",
              choices: uiQuestion.choices.map(
                (choice: { name: any; value: any; isCorrect: any }) => ({
                  name: choice.name,
                  value: choice.value,
                  isCorrect: choice.isCorrect,
                })
              ),
            };
          } else {
            console.error("Unsupported question type:", uiQuestion.type);
            continue;
          }
          const scenario = {
            name: "scenario_" + targetQuestion.order,
            questions: [targetQuestion],
          };
          newScenarios.push(scenario);
        }
        customSurvey.scenarios = newScenarios;
      }
      return customSurvey;
    },
    progressShowing() {
      const step = this.step;
      const array1 = ["Custom_Survey"];

      const found = array1.find((element) => element == step);
      if (found) {
        return true;
      }
      return false;
    },
    selectScenario() {
      let value = true;
      const scenario = this.customSurveyScenario;
      if (scenario[this.scenarioIndexShowing - 1]) {
        value = false;
      }
      return value;
    },
    timeSection(): any {
      return this.$store.getters["routes/timeSection"];
    },
    companyColor(): string {
      const defaultCompanyColor = "#1B1C1E";
      const user = this.$store.getters["user/user"];
      const userRoute = this.$store.getters["routes/user"];
      if (user && user.color) {
        return user.color;
      } else if (userRoute && userRoute.color) {
        return userRoute.color;
      }
      return defaultCompanyColor;
    },
    isLightColor(): boolean {
      const user = this.$store.getters["routes/user"] || {};
      const color = user.color || "#1B1C1E";
      return helpers.isLightColor(color);
    },
    formTemplate(): any {
      return this.$store.getters["assessment/formTemplate"] || {};
    },
    isV2(): boolean {
      return Object.prototype.hasOwnProperty.call(
        this.formTemplate,
        "values_and_traits_v_2"
      );
    },
  },
  props: [
    "lastSection",
    "currentStep",
    "doPreviousStep",
    "doNextStep",
    "setTimerLabel",
    "setTimerPercentage",
  ],
  data() {
    return {
      step: "introduce_custom_survey",
      hasOpenEndedQuestion: false,
      skipOpenEndedQuestion: false,
      openedQuestion: "",
      canNextStep: false,
      canNextStepAll: false,
      saved: false,
      scenarioIndexShowing: 1,
      customSurveyScenario: [],
      timer: 0,
      timerLabel: "0:00",
      percentageLabel: 0,
      isStarted: false,
      timeout: 300,
      selectedChoices: [],
      openendedAnswer: "",
    };
  },
  mounted() {
    this.timeout = 10 * 60 + this.extratime * 60;

    const minutes = this.timeout % 60;
    const minute = Math.floor(this.timeout / 60);
    this.timerLabel = `${minute}:${minutes < 10 ? "0" + minutes : minutes}`;

    if (!this.isStarted) {
      const order = this.currentStep.order - 1;
      ElMessageBox.alert(
        (this as any).$t("question.customSurveys.get_started_message", {
          step: order,
          minute: minute,
        }),
        (this as any).$t("question.common.move_on_step_n", {
          step: order,
        }),
        {
          dangerouslyUseHTMLString: true,
          showClose: true,
          confirmButtonText: (this as any).$t("question.common.Start"),
          callback: () => {
            this.getStarted();
          },
        }
      );
    }
  },
  methods: {
    getStarted() {
      this.step = "Custom_Survey";
      this.countDownTime();
    },
    gotoPreviousStep() {
      console.log("gotoPreviousStep()");
    },
    async gotoNextStep() {
      this.saveTimeSection();
      this.lastSection ? await this.finishAssessment() : this.nextSectionBTN();
    },
    optionCls(option: { value: any }) {
      let cls = "";
      const index = parseInt(option.value);
      if (index) {
        cls = "";
      }
      return cls;
    },
    toggleChoice(question: any, indexChoice: number) {
      for (let i = 0; i < question.choices.length; i++) {
        if (i === indexChoice) {
          question.choices[i].checked = true;
        } else {
          question.choices[i].checked = false;
        }
      }
    },
    checkboxClass(index: any, indexChoice: any) {
      let cls = "";
      const scenarios = this.customSurvey.scenarios;
      if (index >= 0 && index < scenarios.length) {
        const surveyItem = scenarios[index];
        const answer = _.get(surveyItem, "answer", []);
        if (_.indexOf(answer, indexChoice) !== -1) {
          cls = "item selected";
        }
      }
      return cls;
    },
    AddAnswers(index: any, indexChoice: any, type: any, event: any) {
      const scenarios = this.customSurvey.scenarios;
      if (index >= 0 && index < scenarios.length) {
        const surveyItem = scenarios[index];
        if (type === "open-ended") {
          surveyItem.answer = indexChoice.target.value;
        } else if (type === "multiple-choice") {
          surveyItem.answer = indexChoice;
        } else if (type === "multiple-select") {
          if (event.target.checked) {
            if (!_.has(surveyItem, "answer")) {
              surveyItem.answer = [];
            }
            surveyItem.answer.push(indexChoice);
          } else {
            const idx = surveyItem.answer.findIndex(
              (x: any) => x == indexChoice
            );
            surveyItem.answer.splice(idx, 1);
          }
        }
      }
      const allHaveIndexChoice = scenarios.every(
        (item: { answer: null | undefined }) => {
          let result = item.answer !== undefined && item.answer !== null;
          if (_.isArray(item.answer)) {
            result = _.get(item, "answer.length") > 0;
          }
          return result;
        }
      );

      this.canNextStepAll = allHaveIndexChoice;
      this.$emit("setCanNextStep", this.canNextStepAll);
    },
    applyStepperButtons() {
      // can next step if already select choice
      let isChecked = false;
      const scenarios = this.customSurvey.scenarios;
      for (const i in scenarios) {
        if (scenarios[i].order != this.scenarioIndexShowing) continue;
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          for (const k in question.options) {
            if (question.options[k].checked) {
              isChecked = true;
            }
          }
        }
      }
      this.canNextStep = isChecked;
    },
    finishCustomSurveys() {
      this.step = this.hasOpenEndedQuestion ? "opened_question" : "complete";
    },
    makeFormValues() {
      let values: any = {};
      let answer;
      const name = "custom_survey";
      values[name] = {};
      const scenarios = this.customSurvey.scenarios;
      for (const i in scenarios) {
        const group = _.snakeCase(this.customSurvey.scenarios[i].name);
        values[name][group] = {};
        for (const j in scenarios[i].questions) {
          const question = scenarios[i].questions[j];
          const type = question.type;

          if (type == "open-ended") {
            answer = {
              value: scenarios[i].answer,
            };
          } else if (type == "multiple-choice") {
            answer = {
              value: scenarios[i].answer,
            };
          } else {
            // answer = {
            //   value: scenarios[i].answer.map(
            //     (index: string | number) => scenarios[i].answer[index]
            //   ),
            // };
            answer = {
              value: scenarios[i].answer,
            };
          }
          values[name][group][question.order - 1] = answer.value;
        }
      }
      if (this.hasOpenEndedQuestion && !this.skipOpenEndedQuestion) {
        const feedback = this.openedQuestion || "";
        values.custom_survey_feedback = feedback.trim();
      }
      return values;
    },
    async finishAssessment() {
      clearInterval(intervalCountdown);
      const values = this.saved ? null : this.makeFormValues();
      await this.$store.dispatch("assessment/finish", values, { root: true });
      this.saved = true;
      this.$emit("finishAssessment");
    },
    countDownTime() {
      this.percentageLabel = this.timeout;
      this.timer = this.timeout;
      intervalCountdown = setInterval(() => {
        if (this.timer === 0) {
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_1.title"),
            (this as any).$t("popup.alert.alert_box_1.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t("popup.optional.Continue"),
              callback: () => {
                this.saveTimeSection();
                if (this.hasOpenEndedQuestion) {
                  this.step = "opened_question";
                  this.nextSectionTimeOut();
                } else {
                  this.nextSectionTimeOut();
                  if (this.lastSection) {
                    this.finishAssessment();
                  }
                  this.nextSectionBTN();
                }
              },
            }
          );
          clearInterval(intervalCountdown);
        } else if (this.step == "complete" || this.step == "opened_question") {
          clearInterval(intervalCountdown);
        } else {
          this.timer--;
          let minutes = this.timer % 60;
          this.timerLabel = `${Math.floor(this.timer / 60)}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
        }
      }, 1000);
    },
    clearIntervalSession() {
      clearInterval(intervalSession);
    },
    countDownTimeSession() {
      this.timer = this.timeout;
      intervalSession = setInterval(() => {
        if (this.timer === 0) {
          console.log("countDownTimeSession()");
          ElMessageBox.alert(
            (this as any).$t("popup.alert.alert_box_2.title"),
            (this as any).$t("popup.alert.alert_box_2.description"),
            {
              showClose: false,
              confirmButtonText: (this as any).$t(
                "popup.optional.Go_to_Next_Section"
              ),
              callback: () => {
                this.nextSectionBTN();
              },
            }
          );
          clearInterval(intervalSession);
        } else {
          this.timer--;
        }
      }, 1000);
    },
    setupPreviousStep() {
      const index = this.scenarioIndexShowing;
      if (index == 1) {
        this.step = "Custom_Survey";
      } else {
        this.scenarioIndexShowing = index - 1;
      }
    },
    setupNexStep() {
      if (!this.canNextStep) return;

      this.scenarioIndexShowing++;
      this.applyStepperButtons();
      if (
        this.scenarioIndexShowing >
        Object.keys(this.customSurvey.scenarios).length
      ) {
        this.finishCustomSurveys();
        this.saveTimeSection();
      }
    },
    finishOpenedQuestion() {
      this.step = "complete";
    },
    nextSectionBTN() {
      clearInterval(intervalCountdown);
      const values = this.makeFormValues();
      this.$emit("finishValueAndTraits", values);
    },
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    async nextSectionTimeOut() {
      const code = this.$router.currentRoute.value.params.code || "";
      const statusSectionSurvey = true;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/sectionStates",
        { code, statusSectionSurvey },
        {
          root: true,
        }
      );
    },
    async saveTimeSection() {
      const code = this.$router.currentRoute.value.params.code || "";
      const timeSectionSurvey = this.timer ? this.timer : 0;
      if (!code) {
        this.$router.replace(`/404`);
        return;
      }
      await this.$store.dispatch(
        "assessment/saveSectionTime",
        { code, timeSectionSurvey },
        { root: true }
      );
    },
    nl2br(value: string) {
      return value.replace(/([^>])\n/g, "$1<br />\n");
    },
  },
});
